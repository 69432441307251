.service-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.test-mode {
  color: red;
  background: #fff8dd;
  text-align: center;
  display: none;
  padding: 0.25rem;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-weight: 600;
}

.service-error-container {
  position: fixed;
  width: 70%;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 40%;
}
