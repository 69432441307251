@font-face {
  font-family: 'icons';
  src: url('fonts/icons.eot');
  src: url('fonts/icons.eot?#iefix') format('eot'), url('fonts/icons.ttf') format('truetype'),
    url('fonts/icons.woff') format('woff'), url('fonts/icons.svg#icons') format('svg');
  font-style: normal;
  font-weight: @normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}
@font-face {
  font-family: 'outline-icons';
  src: url('fonts/outline-icons.eot');
  src: url('fonts/outline-icons.eot?#iefix') format('eot'), url('fonts/outline-icons.ttf') format('truetype'),
    url('fonts/outline-icons.woff') format('woff'), url('fonts/outline-icons.svg#outline-icons') format('svg');
  font-style: normal;
  font-weight: @normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}
@font-face {
  font-family: 'brand-icons';
  src: url('fonts/brand-icons.eot');
  src: url('fonts/brand-icons.eot?#iefix') format('eot'), url('fonts/brand-icons.ttf') format('truetype'),
    url('fonts/brand-icons.woff') format('woff'), url('fonts/brand-icons.svg#brand-icons') format('svg');
  font-style: normal;
  font-weight: @normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}


/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/nunito-sans-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
       url('fonts/nunito-sans-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/nunito-sans-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/nunito-sans-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/nunito-sans-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/nunito-sans-v3-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/nunito-sans-v3-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Italic'), local('NunitoSans-Italic'),
       url('fonts/nunito-sans-v3-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/nunito-sans-v3-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/nunito-sans-v3-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/nunito-sans-v3-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/nunito-sans-v3-latin-italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-600 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/nunito-sans-v3-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
       url('fonts/nunito-sans-v3-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/nunito-sans-v3-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/nunito-sans-v3-latin-600.woff') format('woff'), /* Modern Browsers */
       url('fonts/nunito-sans-v3-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/nunito-sans-v3-latin-600.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-600italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  src: url('fonts/nunito-sans-v3-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans SemiBold Italic'), local('NunitoSans-SemiBoldItalic'),
       url('fonts/nunito-sans-v3-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/nunito-sans-v3-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/nunito-sans-v3-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/nunito-sans-v3-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/nunito-sans-v3-latin-600italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-900 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/nunito-sans-v3-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Black'), local('NunitoSans-Black'),
       url('fonts/nunito-sans-v3-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/nunito-sans-v3-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/nunito-sans-v3-latin-900.woff') format('woff'), /* Modern Browsers */
       url('fonts/nunito-sans-v3-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/nunito-sans-v3-latin-900.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-900italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  src: url('fonts/nunito-sans-v3-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Black Italic'), local('NunitoSans-BlackItalic'),
       url('fonts/nunito-sans-v3-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/nunito-sans-v3-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/nunito-sans-v3-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/nunito-sans-v3-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/nunito-sans-v3-latin-900italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
